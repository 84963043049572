import { PsychologistDetailInfoStoryblok } from "@/types/component-types-sb";
import { Props } from "@/types/core";

import { PsychologistResponse } from "@/services/psychologist.service";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";

import { CheckSvg } from "assets/icons/CheckSvg";
import { CloseSvg } from "assets/icons/CloseSvg";
import { ForumSvg } from "assets/icons/ForumSvg";

import { ClientAgeRange, ScrollAnchorIds } from "enums";
import { cn, hasAgeRangeNoConfig, languages } from "utils";
import { intersection, isEmpty } from "lodash";

type CustomProps = Props<PsychologistDetailInfoStoryblok> & {
  psychologist: PsychologistResponse;
};

export const PsychologistDetailInfo = ({ blok, psychologist }: CustomProps) => {
  const { helmControl } = psychologist;

  const psychologistLanguages =
    helmControl?.bonaFides?.languages?.value
      .map((language) => languages?.[language])
      .join(", ") || "Unknown";

  const renderBadgeAgeRange = (ageRanges: ClientAgeRange[], label: string) => {
    const configRanges = helmControl?.clientAgeRange;

    const isValidAgeRange =
      !isEmpty(intersection(configRanges, ageRanges)) ||
      hasAgeRangeNoConfig(configRanges);

    const SvgIcon = isValidAgeRange ? CheckSvg : CloseSvg;

    return (
      <div
        className={cn(
          "flex flex-row items-center justify-start w-full gap-2 pb-2 border-b border-secondary-darker md:border-none md:w-fit md:justify-center md:pb-0",
          {
            "text-disabled pointer-events-none bg-transparent opacity-40":
              !isValidAgeRange,
          }
        )}
      >
        <SvgIcon className="w-10 h-10 p-2 rounded-full fill-primary bg-secondary" />
        <span className="font-medium text-primary">{label}</span>
      </div>
    );
  };

  return (
    <div
      {...storyblokEditable(blok)}
      className="flex flex-col items-start justify-center gap-5"
      id={ScrollAnchorIds.PSYCHOLOGIST_DETAIL_INFO}
    >
      <div className="flex gap-y-5 gap-x-8 text-sm flex-row flex-wrap w-full justify-start items-center md:py-3 lg:flex-col lg:items-start xl:flex-row">
        {renderBadgeAgeRange(
          [
            ClientAgeRange.YOUTH,
            ClientAgeRange.ADULTS,
            ClientAgeRange.OLDER_ADULTS,
          ],
          "Adults"
        )}

        {renderBadgeAgeRange(
          [ClientAgeRange.TEENAGERS],
          "Young people (13-17)"
        )}

        <div className="flex flex-row items-center justify-start w-full gap-2 pb-4 border-b border-secondary-darker md:border-none md:w-fit md:justify-center md:pb-0">
          <span>
            <ForumSvg className="w-9 h-9 fill-primary" />
          </span>
          <div className="font-medium text-primary">
            {psychologistLanguages}
          </div>
        </div>
      </div>

      {blok.body?.map((blokItem) => (
        <StoryblokComponent
          key={blokItem._uid}
          blok={blokItem}
          psychologist={psychologist}
        />
      ))}
    </div>
  );
};
