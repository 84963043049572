import { Profile } from "models/client.model";
import { StateCreator, create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";

type State = {
  profile: Profile;
};

type Actions = {
  setProfile: (profile: Profile) => void;
  reset: () => void;
};

const initialState: State = { profile: {} };

const middleware = <T>(fn: StateCreator<T>) =>
  devtools(
    persist(fn, {
      name: "profileStore",
      storage: createJSONStorage(() => sessionStorage),
    })
  );

export const useProfileStore = create<State & Actions>()(
  middleware((set) => ({
    profile: initialState.profile,

    setProfile: (profile) => set({ profile }),
    reset: () => set(initialState),
  }))
);

export const getProfileStoreData = (): Profile | null => {
  return useProfileStore.getState().profile;
};
