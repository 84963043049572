import {
  addHours,
  addMinutes,
  addMonths,
  addYears,
  compareAsc,
  differenceInMinutes,
  differenceInSeconds,
  differenceInYears,
  endOfWeek,
  isBefore,
  isFuture,
  isValid,
  isWithinInterval,
  parse,
  parseISO,
} from "date-fns";
import { format, getTimezoneOffset, toZonedTime } from "date-fns-tz";

import {
  CALENDAR_DATE_FORMAT,
  DEFAULT_HOLDING_TIMEOUT_MINUTES,
} from "core/booking.constant";
import { DateFormatType, TimeZoneType } from "enums";
import { Info } from "luxon";

export const toAvailabilityDate = (date: Date) => format(date, "EEE dd MMM");

export const toISODateFormat = (date: Date) => format(date, "yyyy-MM-dd");

export const toCalendarFormat = (date: Date | string) =>
  format(new Date(date), CALENDAR_DATE_FORMAT);

export const toClientDateOfBirthFormat = (date: Date) =>
  format(date, "dd/MM/yyyy");

export const compareTimesHHmm = (
  firstTime: string,
  secondTime: string
): number => {
  const firstDate = parse(firstTime, "HH:mm", new Date());
  const secondDate = parse(secondTime, "HH:mm", new Date());

  return compareAsc(firstDate, secondDate);
};

export const get3MonthsFromToday = (): Date => {
  return addMonths(new Date(), 3);
};

export const toFullDateFormat = (date: Date) => format(date, "eeee, do MMMM");

export const calculateMinute = (
  start: string | undefined,
  end: string | undefined
) => {
  if (!start || !end) return 0;

  const startParsed = parseISO(start);
  const endParsed = parseISO(end);

  return Math.abs(differenceInMinutes(endParsed, startParsed));
};

export const calculateRemainingTime = (startTime?: Date) => {
  if (!startTime) return 0;

  const startParsed = new Date(startTime);
  const endTime = addMinutes(startParsed, DEFAULT_HOLDING_TIMEOUT_MINUTES);

  const remainingTime = differenceInSeconds(endTime, new Date());

  if (remainingTime <= 0) return 0;

  return remainingTime;
};

export const toZonedTimeFormat = (
  utcDateString: Date | string,
  timeZone: TimeZoneType,
  dateFormat = DateFormatType.HOUR_MINUTE
) => {
  const zonedDate = toZonedTime(new Date(utcDateString), timeZone);

  // format the date to the desired format, ensure the timezone is set and daylight saving time is considered
  return format(zonedDate, dateFormat);
};

export const calculateAge = (dateOfBirth: Date | string) => {
  const today = new Date();
  const birthDate = new Date(dateOfBirth);

  let age = differenceInYears(today, birthDate);

  // Adjust if current date is before the birth date in the current year
  if (isBefore(today, addYears(birthDate, age))) {
    age--;
  }

  return age;
};

export const toExpiryDate = (dateString: string | undefined) => {
  if (!dateString) return;

  const parsedDate = parseISO(dateString);

  return format(parsedDate, "MM/yyyy");
};

export const toDateOfBirth = (dateString: string | undefined) => {
  if (!dateString) return;

  const parsedDate = parseISO(dateString);

  return format(parsedDate, "yyyy-MM-dd");
};

export const parseProfileDateOfBirth = (dateOfBirth?: string) => {
  if (!dateOfBirth) return;

  const dob = parse(dateOfBirth, "dd/MM/yyyy", new Date());

  return toDateOfBirth(toISODateFormat(dob));
};

export const toBlogDateFormat = (
  dateString?: string,
  dateFormat = "dd MMM yyyy"
) => {
  if (!dateString) return format(new Date(), dateFormat);

  return format(new Date(dateString), dateFormat);
};

export const toHourMinuteFormat = (date: Date) => format(date, "HH:mm");

export const isDateInWeekRanges = (date: Date, weekStartDates: string[]) => {
  return weekStartDates.some((weekStart) =>
    isWithinInterval(date, {
      start: parseISO(weekStart),
      end: endOfWeek(parseISO(weekStart)),
    })
  );
};

export const isLessThan48Hours = (date: Date) => {
  return isBefore(date, addHours(new Date(), 48));
};

export const isLessThan2Hours = (date: Date) => {
  return isBefore(date, addHours(new Date(), 2));
};

export const isLessThan15MinutesFromNow = (date: Date) => {
  const timeoutDate = addMinutes(date, 15);

  return isBefore(new Date(), timeoutDate);
};

export const isFutureDate = (date: Date, timeZone?: TimeZoneType): boolean => {
  const zonedDate = timeZone ? toZonedTime(date, timeZone) : date;

  return isFuture(zonedDate);
};

export const toISODateFormatFromProfileExpiryDate = (
  dateString: string | undefined
) => {
  if (!dateString) return format(new Date(), "yyyy-MM-dd");

  const parsedDate = parse(dateString, "MM/yyyy", new Date());

  return format(parsedDate, "yyyy-MM-dd");
};

export const isDaylightSavingTime = (timeZone: TimeZoneType): boolean => {
  const nowUtc = toZonedTime(new Date(), "UTC");

  const offsetNonDst = getTimezoneOffset(timeZone);

  const offsetDst = getTimezoneOffset(timeZone, nowUtc);

  return offsetNonDst !== offsetDst;
};

export const isTimezoneDST = (timeZone: TimeZoneType): boolean => {
  return Info.hasDST(timeZone);
};

export const isValidDate = (date: string | undefined) => {
  if (!date) return false;

  const parsedDate = parse(date, CALENDAR_DATE_FORMAT, new Date());

  return isValid(parsedDate);
};

export const parseProfileDateOfBirthToDate = (
  dateOfBirth: string
): Date => {
  return parse(dateOfBirth, "dd/MM/yyyy", new Date());
};
