import { WarningSvg } from "assets/icons/WarningSvg";

type Props = {
  message: string;
};

export const AgeRangeNotice = ({ message, ...props }: Props) => {
  return (
    <div className="flex flex-row gap-5 mt-5 p-4 md:p-7 bg-alerts-warning rounded-lg mx-auto w-full text-base">
      <div className="mt-1">
        <WarningSvg />
      </div>

      <p>{message}</p>
    </div>
  );
};
