import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { WarningSvg } from "assets/icons/WarningSvg";
import { NoticeBoxStoryblok } from "types/component-types-sb";
import { Props } from "types/core";
import { motion } from "framer-motion";

const variants = {
  hidden: { opacity: 0, y: -20 },
  visible: { opacity: 1, y: 0 },
};

export const NoticeBox = ({ blok }: Props<NoticeBoxStoryblok>) => {
  return (
    <motion.div
      className="flex max-sm:flex-col flex-row gap-5 p-7 bg-alerts-warning rounded-lg mx-auto w-full"
      {...storyblokEditable(blok)}
      initial="hidden"
      animate="visible"
      variants={variants}
      transition={{ duration: 0.5 }}
    >
      <div className="mt-2">
        <WarningSvg />
      </div>
      <div>
        {blok.content?.map((item) => (
          <StoryblokComponent key={item._uid} blok={item} />
        ))}
      </div>
    </motion.div>
  );
};
