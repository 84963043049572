/* eslint-disable react-hooks/exhaustive-deps */
import { useAuth0 } from "@auth0/auth0-react";
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { AxiosError, HttpStatusCode } from "axios";
import { defaultTo, find, first, get, isEmpty, isEqual, isNull } from "lodash";
import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useMount, useToggle } from "react-use";
import { match } from "ts-pattern";
import { PsychologistDetailFunctionsStoryblok } from "types/component-types-sb";
import { Props } from "types/core";

import { SpinnerLoading } from "assets/icons/SpinnerLoading";
import { TooltipCloseSvg } from "assets/icons/TooltipCloseSvg";
import { DropdownSelectionWrapper } from "components/DropdownSelectionWrapper/DropdownSelectionWrapper";
import { ClinicianBookingNotice } from "components/psychologist-detail/ClinicianBookingNotice";
import { MobileTimeSlotAccordion } from "components/psychologist-detail/MobileTimeSlotAccordion";
import { TimeSlotItem } from "components/psychologist-detail/TimeSlotItem";
import { DatePickerSkeleton } from "components/skeleton/DatePickerSkeleton";
import { DEFAULT_APPOINTMENT_TYPE } from "core/booking.constant";
import {
  BookingRuleType,
  ClientAgeRange,
  ConsultPreferenceType,
  ScrollAnchorIds,
  TimeZoneType,
} from "enums";
import { useBreakpoint } from "hooks/useBreakpoint";
import { useCancelQuery } from "hooks/useCancelQuery";
import { useSwrAttachedClinician } from "hooks/useSwrAttachedClinician";
import { useSwrBookingRuleType } from "hooks/useSwrBookingRuleType";
import { useSwrLastAppointment } from "hooks/useSwrLastAppointment";
import { useSwrMyProfile } from "hooks/useSwrMyProfile";
import {
  Funding,
  ProfileUpdatePayload,
  ReserveAppointmentLocalStorageData,
  SelectionOption,
  TimeSlot,
} from "models";
import { Routes } from "routes/main.routes";
import {
  claimReserveAppointmentAsync,
  getReserveDetailsByIdAsync,
} from "services/booking.service";
import {
  PsychologistAvailableAppointmentResponse,
  PsychologistResponse,
  getPsychologistAppointmentsAsync,
  getPsychologistAvailabilitiesAsync,
} from "services/psychologist.service";
import {
  reserveAppointmentForNewClientAsync,
  updatePatientProfileAsync,
} from "services/signup.service";
import { useBookingStore } from "stores/booking.store";
import { useProfileStore } from "stores/profile.store";
import { useTimeLogStore } from "stores/timelog.store";
import {
  calculateAge,
  cn,
  extractDisableWeekRanges,
  get3MonthsFromToday,
  getDefaultButtonStyles,
  getLastBookedAppointmentTypeId,
  getNextBookingNavigation,
  isDateInWeekRanges,
  isFilledRequiredFieldsGP,
  isGpUser,
  isLessThan48Hours,
  isRoleClinician,
  isValidAgeRange,
  linkStyles,
  parseProfileDateOfBirthToDate,
  psychologistAppointmentsToSelectionOptions,
  redirectTo,
  timeZoneOptions,
  toClaimType,
  toFullDateFormat,
  toFundingType,
  toISODateFormat,
  toZonedTimeFormat,
} from "utils";
import {
  processDVABooking,
  processMedicareBooking,
  processNDISBooking,
  processPsychologistBookingAfterGP,
  processSelfFundBooking,
  processWorkCoverBooking,
} from "utils/booking.util";
import {
  getStoredFundingMethod,
  getStoredTimeZone,
  setStoredFundingMethod,
  setStoredReserveData,
  setStoredTimeZone,
} from "utils/storage.util";
import { customToast } from "utils/toast.util";
import { AgeRangeNotice } from "./AgeRangeNotice";
import { UnMatchingPsychologistNotice } from "./UnMatchingPsychologistNotice";

const DatePicker = React.lazy(() => import("react-datepicker"));

type CustomProps = Props<PsychologistDetailFunctionsStoryblok> & {
  psychologist: PsychologistResponse;

  showClosePanelButton?: boolean;
  onClosePanel?: () => void;
};

export const PsychologistDetailFunctions = React.memo(
  ({
    blok,
    psychologist,
    showClosePanelButton = false,
    onClosePanel,
  }: CustomProps) => {
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    const { data: attachedClinician, isLoading: isLoadingAttachedClinician } =
      useSwrAttachedClinician();
    const { data: profile } = useSwrMyProfile();
    const { data: bookingRuleType } = useSwrBookingRuleType();
    const { data: appointmentHistory, isLoading: isLoadingAppointmentHistory } =
      useSwrLastAppointment();

    const { lastPsychologistAppointment, lastGpAppointment } =
      appointmentHistory || {};

    const breakpoint = useBreakpoint();

    const setProfile = useProfileStore((state) => state.setProfile);
    const { reset: resetTimeLogStore } = useTimeLogStore();
    const { isSubmittingBookNow, toggleSubmittingBookNow } = useBookingStore();

    const queryAvailabilitiesCancellable = useCancelQuery({
      queryFn: getPsychologistAvailabilitiesAsync,
    });

    const profileTimeZone = profile?.timeZone;
    const storedTimeZone = getStoredTimeZone();
    const storedFundingMethod = getStoredFundingMethod();

    const isNewClient =
      bookingRuleType?.bookingRuleType === BookingRuleType.NEW ||
      !isAuthenticated;

    const isMatchedPsychologist =
      psychologist?._id === attachedClinician?.clinician?._id;

    const isMatchedAppointmentType =
      isEmpty(lastPsychologistAppointment) ||
      isEqual(
        toFundingType(lastPsychologistAppointment?.claimType),
        storedFundingMethod
      );

    const isAttachedDataMatched =
      !isAuthenticated ||
      isGpUser() ||
      (isMatchedPsychologist && isMatchedAppointmentType);

    const psychologistAgeRanges = psychologist?.helmControl
      ?.clientAgeRange as ClientAgeRange[];

    const profileDateOfBirth = profile?.dateOfBirth;

    const validateAgeRangeResponse = useMemo(() => {
      return isValidAgeRange({
        inputAge: profileDateOfBirth
          ? calculateAge(parseProfileDateOfBirthToDate(profileDateOfBirth))
          : undefined,
        ageRanges: psychologistAgeRanges,
      });
    }, [profileDateOfBirth, psychologistAgeRanges]);

    const isClinicianLogin = isRoleClinician();

    const [isLoadingAppointmentTypes, toggleLoadingAppointmentTypes] =
      useToggle(true);
    const [isLoadingAvailabilities, toggleLoadingAvailabilities] =
      useToggle(false);
    const [isSubmittingForm, toggleSubmittingForm] = useToggle(false);

    const [psychologistAvailabilities, setPsychologistAvailabilities] =
      useState<PsychologistAvailableAppointmentResponse>();
    const [appointmentTypes, setAppointmentTypes] = useState<SelectionOption[]>(
      []
    );
    const [appointmentTypeSelected, setAppointmentTypeSelected] =
      useState<SelectionOption | null>();

    const [availableDates, setAvailableDates] = useState<Date[]>([]);
    const [selectedDate, setSelectedDate] = useState<Date | null>();

    const [timeSlots, setTimeSlots] = useState<TimeSlot[]>([]);
    const [timeSlotSelected, setTimeSlotSelected] = useState<TimeSlot | null>();

    const [timeZoneSelected, setTimeZoneSelected] =
      useState<TimeZoneType>(storedTimeZone);

    const isNewClientHasNoSlots =
      !isAuthenticated &&
      psychologist?.helmControl?.shouldUseCaseLoad &&
      (psychologist?.caseLoad?.remainingSlots || 0) <= 0;

    useEffect(() => {
      if (!isAuthenticated) return;
      if (!lastPsychologistAppointment) return;

      const lastApptType = getLastBookedAppointmentTypeId(
        lastPsychologistAppointment
      );
      const matchedApptType = find(appointmentTypes, { value: lastApptType });

      setAppointmentTypeSelected(matchedApptType);
    }, [lastPsychologistAppointment, appointmentTypes]);

    useEffect(() => {
      if (!profileTimeZone) return;

      setTimeZoneSelected(profileTimeZone);
    }, [profileTimeZone]);

    useEffect(() => {
      const attachedClaimType = lastPsychologistAppointment?.claimType;

      if ([!attachedClaimType, storedFundingMethod].some(Boolean)) {
        return;
      }

      getPsychologistAppointmentTypesAsync(attachedClaimType);
      const attachedFundingType = toFundingType(attachedClaimType);
      setStoredFundingMethod(attachedFundingType);
    }, [lastPsychologistAppointment?.claimType]);

    const convertToTimeSlotsOfTimeZone = (
      timeSlots: TimeSlot[],
      timeZone: TimeZoneType
    ): TimeSlot[] => {
      return timeSlots.map((timeSlot) => ({
        ...timeSlot,
        startTime: toZonedTimeFormat(timeSlot.startDateTime, timeZone),
        endTime: toZonedTimeFormat(timeSlot.endDateTime, timeZone),
      }));
    };

    const filteredTimeSlotsByTimeOfDay = useMemo(() => {
      return convertToTimeSlotsOfTimeZone(timeSlots, timeZoneSelected);
    }, [timeSlots, timeZoneSelected]);

    const selectedAppointmentTypeOption = useMemo(() => {
      const selectedValue = appointmentTypeSelected?.value;

      if (!selectedValue) return first(appointmentTypes) || null;

      return appointmentTypes.find(({ value }) =>
        isEqual(value, selectedValue)
      );
    }, [appointmentTypes, appointmentTypeSelected?.value]);

    useMount(() => {
      // Fetch data for booking panel
      getPsychologistAppointmentTypesAsync();
    });

    useEffect(() => {
      if (!appointmentTypeSelected?.value) return;

      getPsychologistAvailabilityDetailsAsync();
    }, [appointmentTypeSelected?.value]);

    const getAppointmentTypeSelected = async (
      appointmentTypeList: SelectionOption[]
    ) => {
      if (!lastPsychologistAppointment) {
        return first(appointmentTypeList) || null;
      }

      const lastAppointmentValue = getLastBookedAppointmentTypeId(
        lastPsychologistAppointment
      );

      return find(appointmentTypeList, { value: lastAppointmentValue });
    };

    const getPsychologistAppointmentTypesAsync = async (claimType?: string) => {
      try {
        toggleLoadingAppointmentTypes(true);

        const funding = getStoredFundingMethod();

        const fundingClaim = funding
          ? toClaimType(funding)
          : claimType ?? toClaimType(funding);

        const { data } = await getPsychologistAppointmentsAsync({
          clinicianId: psychologist._id,
          claimType: isGpUser() ? toClaimType(funding) : fundingClaim,
        });

        const appointmentTypeList: SelectionOption[] =
          psychologistAppointmentsToSelectionOptions(data);

        setAppointmentTypes(appointmentTypeList);

        const appointmentTypeSelected = await getAppointmentTypeSelected(
          appointmentTypeList
        );

        setAppointmentTypeSelected(appointmentTypeSelected);

        if (isNewClientHasNoSlots) {
          customToast.warning(
            <>
              Not currently accepting new clients.
              <br />
              If you were referred, we can assist via email{" "}
              <a className={linkStyles} href="mailto:team@someone.health">
                team@someone.health
              </a>
            </>,
            { duration: Infinity }
          );
        }

        if (isEmpty(appointmentTypeList)) {
          customToast.info(
            "There are currently no appointment types available for this psychologist."
          );
        }
      } catch (err) {
        console.log("[error]: >>", err);
      } finally {
        toggleLoadingAppointmentTypes(false);
      }
    };

    const getPsychologistAvailabilityDetailsAsync = async () => {
      if (!appointmentTypeSelected?.value) return;

      try {
        toggleLoadingAvailabilities(true);

        const data = await queryAvailabilitiesCancellable.queryAsync({
          appointmentTypeId: appointmentTypeSelected.value,
          clinicianId: psychologist._id,
          date: toISODateFormat(new Date()),
          to: toISODateFormat(get3MonthsFromToday()),
          type: "booking",
        });

        setPsychologistAvailabilities(data);

        toggleLoadingAvailabilities(false);

        const allocations = psychologist?.appointmentAllocations ?? [];

        const disabledWeekRanges: string[] =
          extractDisableWeekRanges(allocations);

        const nextAppointmentDates = Object.entries(data.timeSlots)
          .filter(([key, value]) => value.isAvailable)
          .map(([key, value]) => new Date(key))
          .filter((date) => !isDateInWeekRanges(date, disabledWeekRanges))
          .filter((date) => {
            return isNewClient ? !isLessThan48Hours(date) : true;
          });

        setAvailableDates(nextAppointmentDates);

        setSelectedDate(null);

        setTimeSlots([]);

        setTimeSlotSelected(null);
      } catch (err) {
        console.log("[error]: >>", err);
      } finally {
      }
    };

    const handleSelectAppointmentType = (option: SelectionOption) => {
      setAppointmentTypeSelected(option);
    };

    const handleSelectDate = (selectedDate: Date) => {
      if (!selectedDate) return;

      setSelectedDate(selectedDate);

      const selectedDateString = toISODateFormat(selectedDate);

      const timeSlotsAvailabilities =
        psychologistAvailabilities?.timeSlots[selectedDateString].timeSlots;

      if (!timeSlotsAvailabilities) return;

      setTimeSlots(timeSlotsAvailabilities);

      setTimeSlotSelected(null);
    };

    const handleSelectTimeSlot = (timeSlot: TimeSlot) => {
      setTimeSlotSelected(timeSlot);
    };

    const handleCompleteBookingAsync = async () => {
      if (
        !selectedDate ||
        !timeSlotSelected ||
        !psychologist ||
        !appointmentTypeSelected?.value
      ) {
        return;
      }

      toggleSubmittingForm(true);
      toggleSubmittingBookNow(true);

      try {
        const reserveAppointmentResponse =
          await reserveAppointmentForNewClientAsync({
            timeSlotSelected,
            appointmentTypeSelected: appointmentTypeSelected?.value,
            consultMethod: ConsultPreferenceType.VIDEO_CALL,
            isNewClient: isNewClient,
          });

        const reserveId = reserveAppointmentResponse?.data?.reserveId;

        const { data: reserveAppointmentDetails } =
          await getReserveDetailsByIdAsync(reserveId);

        setStoredReserveData({
          psychologist: psychologist,
          reserveAppointment: reserveAppointmentDetails,
        } as ReserveAppointmentLocalStorageData);

        resetTimeLogStore();
        if (isNull(storedFundingMethod)) {
          setStoredFundingMethod(DEFAULT_APPOINTMENT_TYPE);
        }

        if (isAuthenticated) {
          const accessToken = await getAccessTokenSilently();

          await claimReserveAppointmentAsync(reserveId, accessToken);

          await match(bookingRuleType)
            .with({ bookingRuleType: BookingRuleType.NEW }, () => {
              const { medicare, address } = profile || {};
              const currentStoredFundingMethod = defaultTo(
                getStoredFundingMethod(),
                DEFAULT_APPOINTMENT_TYPE
              );

              const bookingNavigationRoute = getNextBookingNavigation();

              return match(currentStoredFundingMethod)
                .with(Funding.DVA, () => {
                  if ([medicare, medicare?.dva, address].some(isEmpty)) {
                    redirectTo(bookingNavigationRoute);

                    return;
                  }

                  redirectTo(Routes.BOOKING_CONFIRM);
                })
                .otherwise(() => {
                  redirectTo(bookingNavigationRoute);
                });
            })
            .with({ bookingRuleType: BookingRuleType.EXISTING }, async () => {
              const isDvaClient: boolean =
                isEqual(storedFundingMethod, Funding.DVA) ||
                (!isEmpty(profile?.medicare?.dva) &&
                  isEqual(
                    lastPsychologistAppointment?.claimType,
                    toClaimType(Funding.DVA)
                  ));

              const appointmentClaimType = first(
                reserveAppointmentDetails.appointments
              )?.claimType;

              const fundingMethod: Funding = isDvaClient
                ? Funding.DVA
                : toFundingType(appointmentClaimType);

              const isCompletedGPBooking =
                lastGpAppointment && isFilledRequiredFieldsGP(profile);

              if (isCompletedGPBooking) {
                await handleBookWithExistingGPBooking(
                  fundingMethod,
                  reserveId,
                  accessToken
                );
              } else {
                await handleBookWithExistingPsychologistBooking(
                  fundingMethod,
                  reserveId,
                  accessToken
                );
              }

              setStoredFundingMethod(fundingMethod);
            })
            .otherwise(() => {});
        } else {
          redirectTo(Routes.SIGNUP);
        }
      } catch (err) {
        console.log("[error]: >>", err);

        const statusCode = (err as AxiosError).response?.status;

        if (statusCode === HttpStatusCode.Conflict) {
          customToast.error(
            "Appointment slot already taken. Please choose another time",
            { duration: 5000 }
          );
        } else if (statusCode === HttpStatusCode.Forbidden) {
          const errorMsg = get(err, "response.data.message");

          customToast.error(
            errorMsg ? (
              <div>{errorMsg}</div>
            ) : (
              <div>
                The <strong>{appointmentTypeSelected?.label}</strong>{" "}
                appointment type is set to all assignment mode which is not
                supported for reservation.
              </div>
            ),
            { duration: 5000 }
          );
        }
      } finally {
        toggleSubmittingForm(false);
        toggleSubmittingBookNow(false);
      }
    };

    const isNotLoadingData =
      !isLoadingAppointmentTypes &&
      !isLoadingAvailabilities &&
      !isLoadingAppointmentHistory;

    const hasSelectedTimeAndDate = timeSlotSelected && selectedDate;

    const isValidPsychologistAndAppointmentType =
      isAuthenticated && !isNewClient ? isAttachedDataMatched : true;

    const canSubmitForm =
      isNotLoadingData &&
      hasSelectedTimeAndDate &&
      !isSubmittingForm &&
      isValidPsychologistAndAppointmentType;

    const shouldShowTimeSlot =
      filteredTimeSlotsByTimeOfDay?.length > 0 && isNotLoadingData;

    const isDisableSubmitForm =
      !canSubmitForm ||
      isNewClientHasNoSlots ||
      !validateAgeRangeResponse.isValid;

    const handleChangeTimeZone = (selectedTimeZone: SelectionOption) => {
      const selectedTimeZoneValue = selectedTimeZone.value as TimeZoneType;

      setTimeZoneSelected(selectedTimeZoneValue);

      if (!isAuthenticated) {
        setStoredTimeZone({ timeZone: selectedTimeZoneValue });

        return;
      } else {
        handleUpdateTimeZoneProfileAsync({
          timeZone: selectedTimeZoneValue,
          address: profile?.address,
        });
      }
    };

    const handleUpdateTimeZoneProfileAsync = async (
      profilePayload: ProfileUpdatePayload
    ) => {
      try {
        const accessToken = await getAccessTokenSilently();

        await updatePatientProfileAsync(profilePayload, accessToken);

        setProfile({ ...profile, timeZone: profilePayload.timeZone });
      } catch (err) {
        console.log("[error]: >>", err);
      }
    };

    const handleBookWithExistingGPBooking = async (
      fundingMethod: Funding,
      reserveId: string,
      accessToken: string
    ) => {
      await match(fundingMethod)
        .with(Funding.BULK_BILL, Funding.REBATE, async () => {
          await processPsychologistBookingAfterGP(
            profile,
            reserveId,
            accessToken
          );
        })
        .with(Funding.WORK_COVER, async () => {
          await processWorkCoverBooking(
            profile,
            lastPsychologistAppointment,
            reserveId,
            accessToken
          );
        })
        .with(Funding.DVA, async () => {
          await processDVABooking(profile, reserveId, accessToken);
        })
        .with(Funding.NDIS, async () => {
          await processNDISBooking(
            profile,
            lastPsychologistAppointment,
            reserveId,
            accessToken
          );
        })
        .with(Funding.SELF_FUNDED, async () => {
          await processPsychologistBookingAfterGP(
            profile,
            reserveId,
            accessToken
          );
        })
        .otherwise(() => {});
    };

    const handleBookWithExistingPsychologistBooking = async (
      fundingMethod: Funding,
      reserveId: string,
      accessToken: string
    ) => {
      await match(fundingMethod)
        .with(Funding.BULK_BILL, Funding.REBATE, async () => {
          await processMedicareBooking(profile, reserveId, accessToken);
        })
        .with(Funding.WORK_COVER, async () => {
          await processWorkCoverBooking(
            profile,
            lastPsychologistAppointment,
            reserveId,
            accessToken
          );
        })
        .with(Funding.DVA, async () => {
          await processDVABooking(profile, reserveId, accessToken);
        })
        .with(Funding.NDIS, async () => {
          await processNDISBooking(
            profile,
            lastPsychologistAppointment,
            reserveId,
            accessToken
          );
        })
        .with(Funding.SELF_FUNDED, async () => {
          await processSelfFundBooking(profile, reserveId, accessToken);
        })
        .otherwise(() => {});
    };

    const renderAgeRangeNotice = useCallback(() => {
      if (validateAgeRangeResponse.isValid) return <></>;

      return <AgeRangeNotice message={validateAgeRangeResponse.message} />;
    }, [validateAgeRangeResponse]);

    return (
      <div
        {...storyblokEditable(blok)}
        className={cn("relative")}
        id={ScrollAnchorIds.PSYCHOLOGIST_DETAIL_FUNCTIONS}
      >
        {showClosePanelButton && (
          <span
            className={cn(
              "absolute right-[1rem] top-[0.8rem]",
              isSubmittingBookNow && "hidden"
            )}
            onClick={() => onClosePanel?.()}
          >
            <TooltipCloseSvg className="hover:cursor-pointer" />
          </span>
        )}

        <div
          className={cn(
            "flex flex-col items-start justify-start pt-3 md:pt-6 bg-light-grey rounded-3xl"
          )}
        >
          <div
            className={cn(
              "mb-3 md:mb-4 leading-8 text-xl px-4 md:px-7 line-clamp-2",
              breakpoint.isXs && "mr-4" // avoid overlap x icon small screen
            )}
            title={blok.title}
          >
            {blok.title}
          </div>

          <div className="flex flex-col items-center justify-center w-full gap-y-3 md:gap-y-5 border-b border-b-secondary-darker pb-5 md:pb-7 z-[2] px-4 md:px-7">
            {/* APPOINTMENT TYPE */}
            <div className="w-full">
              {blok.appointment_type_dropdowns?.map(
                (appointmentTypeDropdownBlok) => (
                  <StoryblokComponent
                    key={appointmentTypeDropdownBlok._uid}
                    blok={appointmentTypeDropdownBlok}
                    isLoading={isLoadingAppointmentTypes}
                    options={appointmentTypes}
                    selectedOption={selectedAppointmentTypeOption}
                    onChangeSingleSelection={handleSelectAppointmentType}
                    isRequired
                  />
                )
              )}
            </div>

            {/* CONSULT REFERENCE */}
            {/* <div className="w-full">
              {blok.consult_method_dropdowns?.map(
                (consultMethodDropdownBlok) => (
                  <StoryblokComponent
                    key={consultMethodDropdownBlok._uid}
                    blok={consultMethodDropdownBlok}
                    isLoading={
                      isLoadingAvailabilities || isLoadingAppointmentTypes
                    }
                    options={deliveryOptions}
                    selectedOption={getSingleSelectedOption(
                      deliveryOptions,
                      consultMethod
                    )}
                    onChangeSingleSelection={handleSelectConsultMethod}
                    isRequired
                  />
                )
              )}
            </div> */}
          </div>

          {/* CALENDAR */}
          <div className="flex items-center justify-center w-full p-1.5 pb-5 md:px-7 md:pt-3 md:pb-5 border-b border-b-secondary-darker z-[1] date-picker-inline-styles">
            {(isEmpty(availableDates) && !isNotLoadingData) ||
            isSubmittingForm ||
            !isNotLoadingData ? (
              <DatePickerSkeleton />
            ) : (
              <Suspense fallback={<DatePickerSkeleton />}>
                <DatePicker
                  selected={selectedDate}
                  includeDates={availableDates}
                  onChange={handleSelectDate}
                  dateFormatCalendar="MMM yyyy"
                  useWeekdaysShort
                  inline
                  adjustDateOnChange={true}
                />
              </Suspense>
            )}
          </div>

          <div className="flex flex-col w-full p-5 md:p-7 !pb-1.5 bg-secondary-light rounded-b-3xl gap-y-2 md:gap-y-3">
            {selectedDate && (
              <div className="text-lg font-normal leading-6">
                {toFullDateFormat(selectedDate)}
              </div>
            )}

            <div className="flex flex-col text-15 md:text-base font-normal leading-7">
              <div className="flex flex-row items-start justify-end w-full gap-x-2">
                <div className="flex justify-start w-full">
                  <div>Timezone:</div>
                  <span className="ml-2">{timeZoneSelected}</span>
                </div>

                <DropdownSelectionWrapper
                  options={timeZoneOptions}
                  onSelect={handleChangeTimeZone}
                >
                  <div className="flex justify-end underline cursor-pointer">
                    Change
                  </div>
                </DropdownSelectionWrapper>
              </div>
            </div>

            {shouldShowTimeSlot && breakpoint.isXs && (
              <MobileTimeSlotAccordion
                slots={filteredTimeSlotsByTimeOfDay}
                timeSlotSelected={timeSlotSelected}
                onSelectTimeSlot={handleSelectTimeSlot}
                timeZoneSelected={timeZoneSelected}
              />
            )}

            {shouldShowTimeSlot && !breakpoint.isXs && (
              <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-x-4 gap-y-3">
                {filteredTimeSlotsByTimeOfDay?.map((timeSlot, index) => (
                  <TimeSlotItem
                    key={index}
                    timeSlot={timeSlot}
                    timeSlotSelected={timeSlotSelected}
                    timeZoneSelected={timeZoneSelected}
                    onSelectTimeSlot={handleSelectTimeSlot}
                  />
                ))}
              </div>
            )}

            <div className="flex flex-col items-center justify-center mt-3 gap-y-4">
              <button
                onClick={handleCompleteBookingAsync}
                disabled={isDisableSubmitForm}
                className={cn(
                  getDefaultButtonStyles(isSubmittingForm),
                  "w-full flex justify-center items-center"
                )}
              >
                {isAuthenticated ? (
                  <span>Complete your booking</span>
                ) : (
                  <span>Sign up to complete your booking</span>
                )}

                {isSubmittingForm && (
                  <SpinnerLoading className="w-10 h-10 ms-2" />
                )}
              </button>

              <div>
                {blok.sign_up_message?.map((signUpMessageBlok) => (
                  <StoryblokComponent
                    key={signUpMessageBlok._uid}
                    blok={signUpMessageBlok}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>

        {!isAttachedDataMatched && !isLoadingAttachedClinician && (
          <div className="mt-5">
            {isClinicianLogin ? (
              <ClinicianBookingNotice />
            ) : (
              <UnMatchingPsychologistNotice />
            )}
          </div>
        )}

        {renderAgeRangeNotice()}
      </div>
    );
  }
);
